import { Link, useParams } from "@remix-run/react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useEffect, useRef } from "react";
import { useIsClient } from "usehooks-ts";

import { ErrorComponent } from "~/components/error-component";
import { VideoPlayer } from "~/components/video-player";
import { useFeedback } from "~/hooks/dialog-context";
import { useDailyData } from "~/hooks/useDailyData";
import { useEventProgress } from "~/hooks/useEventProgress";
import { useFeedbackDismissed } from "~/hooks/useFeedbackDismissed";
import { usePhoneEntered } from "~/hooks/usePhoneEntered";
import {
  SECONDS_REMAINING_TO_COMPLETE,
  VIDEO_TRACKING_INTERVAL_MS,
  VIDEO_TRACKING_PERCENTAGE_INTERVAL,
} from "~/lib/config";
import { getPercentageWatched, getSecondsRemaining, videoIsPlaying } from "~/lib/events";
import { segment } from "~/lib/segment";

dayjs.extend(advancedFormat);

export default function DailyContent() {
  const params = useParams();
  const isClient = useIsClient();
  const player = useRef<HTMLVideoElement>(null);
  const { eventId, eventDays, isProd } = useDailyData();
  const { progress, markDayComplete, markDayIncomplete, resetProgress } = useEventProgress(eventId);
  const [feedbackDismissed, setFeedbackDismissed] = useFeedbackDismissed();
  const { openFeedbackDialog, openFinalDialog } = useFeedback();
  const [phoneEntered, setPhoneEntered] = usePhoneEntered();

  const dateParam = params.date as string;
  const selectedDayFromData = eventDays.find((day) => day.date === dateParam);

  useEffect(() => {
    const percentageWatchedEvents: Array<number> = [];

    const interval = setInterval(() => {
      // ---> NOTE: Track video events here -- this runs every second
      const el = player.current;
      if (!el) return;

      const secondsRemaining = getSecondsRemaining(el);
      const percentageWatched = getPercentageWatched(el);

      // EVENT: Video Progress
      if (
        percentageWatched % VIDEO_TRACKING_PERCENTAGE_INTERVAL === 0 &&
        percentageWatched !== 0 &&
        videoIsPlaying(el)
      ) {
        // Prevent duplicate events because several timestamps can equate to the same percentage
        if (percentageWatchedEvents.includes(percentageWatched)) {
          return;
        }

        segment.track("Video Progress", {
          percentageWatched,
          date: params.date,
          videoURL: selectedDayFromData?.videoURL,
          currentTime: el.currentTime,
        });
        percentageWatchedEvents.push(percentageWatched);
      }

      // EVENT: Day Complete
      if (secondsRemaining < SECONDS_REMAINING_TO_COMPLETE && !progress.includes(dateParam) && videoIsPlaying(el)) {
        segment.track("Day Complete", {
          date: params.date,
          videoURL: selectedDayFromData?.videoURL,
        });

        // Trigger feedback dialog if it's the first time completing a day or if it's the last day
        // Once the feedback dialog is dismissed, we save a boolean in localStorage to prevent it from showing again
        if (!feedbackDismissed || eventDays.at(-1)?.date === dateParam) {
          openFeedbackDialog();
        } else {
          openFinalDialog();
        }

        markDayComplete(dateParam);
      }
    }, VIDEO_TRACKING_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackDismissed, progress, dateParam, eventDays, selectedDayFromData]);

  if (!dateParam) {
    throw new Error("Date not found");
  }

  if (!isClient) {
    return (
      <div className="mt-12">
        <div className="flex aspect-square size-full animate-pulse justify-center overflow-hidden rounded-[20px] bg-grey/25 shadow-brand sm:shadow-none"></div>
      </div>
    );
  }

  // Day is before the content
  if (dayjs(dateParam).isBefore(dayjs(eventDays.at(0)?.date))) {
    return (
      <div className="mt-6 flex flex-col items-center space-y-4">
        <h1 className="text-balance text-center text-muted-foreground">
          Tune in on {dayjs(eventDays.at(0)?.date).format("MMMM Do")} for daily song-based devotionals to prepare for
          TGCW24.
        </h1>
        <p className="text-balance text-center text-muted-foreground">We can&apos;t wait to worship with you!</p>
      </div>
    );
  }

  // date wasn't found in the data for some reason
  if (!selectedDayFromData) {
    return (
      <div className="mt-6 flex flex-col items-center space-y-4">
        <h1 className="text-center font-bold">No content found for this date.</h1>
        <Link className="inline-block text-center underline" to={`/daily/${eventDays.at(0)?.date}`}>
          Go to the first day.
        </Link>
      </div>
    );
  }

  return (
    <div className="mt-2 space-y-4">
      <div>
        <h1 className="text-balance text-center text-lg font-bold text-primary md:text-2xl">
          {selectedDayFromData.title}
        </h1>
        <p className="text-center text-muted-foreground">
          {dayjs(selectedDayFromData.date).format("MMM D, YYYY")} | {selectedDayFromData.subtitle}
        </p>
      </div>
      <VideoPlayer>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          ref={player}
          slot="media"
          src={selectedDayFromData.videoURL}
          crossOrigin=""
          // TODO: Verify video is fitting in the box correctly
          className="size-full object-cover"
          playsInline
          autoPlay
          poster="/album_art_gkxuyp_c_scale,w_720.png"
        />
      </VideoPlayer>

      {/* Debug info */}
      {!isProd ? (
        <div className="rounded border bg-accent p-4">
          <span className="font-mono">DEBUG</span>
          <h2 className="tabular-nums">Selected Day: {selectedDayFromData.date}</h2>
          <div className="my-4 flex gap-2">
            <button
              onClick={() => markDayComplete(selectedDayFromData.date)}
              className="rounded bg-primary px-2 py-1 text-center text-sm text-primary-foreground"
            >
              Mark complete
            </button>
            <button
              onClick={() => markDayIncomplete(selectedDayFromData.date)}
              className="rounded bg-primary px-2 py-1 text-center text-sm text-primary-foreground"
            >
              Mark incomplete
            </button>
            <button onClick={resetProgress} className="rounded border-2 border-primary px-2 py-1 text-center text-sm">
              Reset
            </button>
          </div>
          <div className="my-4 space-y-4 text-sm">
            <div className="flex items-center gap-2">
              <p>Feedback dismissed: {`${feedbackDismissed}`}</p>
              <button
                className="rounded border-2 border-primary p-1 text-center text-sm"
                onClick={() => setFeedbackDismissed((v) => !v)}
              >
                Toggle
              </button>
            </div>
            <div className="flex items-center gap-2">
              <p>Phone entered: {`${phoneEntered}`}</p>
              <button
                className="rounded border-2 border-primary p-1 text-center text-sm"
                onClick={() => setPhoneEntered((v) => !v)}
              >
                Toggle
              </button>
            </div>
            <button
              className="block rounded border-2 border-primary p-1 text-center text-sm"
              onClick={openFeedbackDialog}
            >
              Trigger Feedback Dialog
            </button>
            <button className="block rounded border-2 border-primary p-1 text-center text-sm" onClick={openFinalDialog}>
              Trigger Final Dialog
            </button>
            <p>Video track interval: {VIDEO_TRACKING_INTERVAL_MS}ms</p>
            <p>Seconds remaining to mark day complete: {SECONDS_REMAINING_TO_COMPLETE}s</p>
            <p>Tracking percentage interval: {VIDEO_TRACKING_PERCENTAGE_INTERVAL}%</p>
          </div>
        </div>
      ) : null}
      {/* End debug info */}
    </div>
  );
}

export function ErrorBoundary() {
  return <ErrorComponent />;
}
