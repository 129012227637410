export function videoIsPlaying(el: HTMLVideoElement) {
  return Boolean(el && !el.paused && !el.ended && !el.seeking);
}

export function getSecondsRemaining(el: HTMLVideoElement) {
  return Math.ceil(el.duration - el.currentTime);
}

export function getPercentageWatched(el: HTMLVideoElement) {
  const percentage = (el.currentTime / el.duration) * 100;
  return Math.round(percentage * 2) / 2;
}
