import { Pause, Play, RotateCcw, RotateCw } from "lucide-react";
import {
  MediaControlBar,
  MediaController,
  MediaMuteButton,
  MediaPlayButton,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaTimeDisplay,
  MediaTimeRange,
  MediaVolumeRange,
} from "media-chrome/react";
import { useMediaQuery } from "usehooks-ts";

export function VideoPlayer({ children }: { children: React.ReactNode }) {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    // TODO: Verify video is fitting on screen with no y scroll
    <div className="mx-auto flex aspect-square justify-center overflow-hidden rounded-[20px] bg-black shadow-brand sm:max-h-[60dvh]">
      <MediaController>
        {children}
        <MediaControlBar>
          <div className="hidden md:block">
            <MediaPlayButton />
          </div>
          <div className="absolute left-1/2 top-1/2 grid -translate-x-1/2 -translate-y-1/2 place-items-center md:hidden">
            <MediaPlayButton>
              <span
                slot="play"
                className="grid size-14 place-items-center rounded-full border-2 border-foreground bg-background text-primary shadow-lg"
              >
                <Play fill="#2F2F2F" />
              </span>
              <span
                slot="pause"
                className="grid size-14 place-items-center rounded-full border-2 border-foreground bg-background text-primary opacity-70 shadow-lg transition hover:opacity-100"
              >
                <Pause fill="#2F2F2F" />
              </span>
            </MediaPlayButton>
          </div>
          <div className="absolute left-[15%] top-1/2 -translate-y-1/2 rounded-full bg-black/50 transition active:bg-black/70 sm:hidden">
            <MediaSeekBackwardButton seekOffset={15}>
              <span slot="icon" className="relative bg-transparent text-white">
                <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[10px]">15</span>
                <RotateCcw className="size-6" />
              </span>
            </MediaSeekBackwardButton>
          </div>
          <div className="absolute right-[15%] top-1/2 -translate-y-1/2 rounded-full bg-black/50 transition active:bg-black/70 sm:hidden">
            <MediaSeekForwardButton seekOffset={15}>
              <span slot="icon" className="relative bg-transparent text-white">
                <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[10px]">15</span>
                <RotateCw className="size-6" />
              </span>
            </MediaSeekForwardButton>
          </div>
          <MediaTimeRange />
          <MediaTimeDisplay showDuration />
          <MediaMuteButton></MediaMuteButton>
          {isDesktop ? <MediaVolumeRange /> : null}
        </MediaControlBar>
      </MediaController>
    </div>
  );
}
